// src/components/TagsInput.js
import { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import './tagInput.css'
function TagsInput({tags, setTags}) {
  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, ...value.split(",")]);

    e.target.value = "";
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }

  return (
    <div className="tags-input-container">
     {tags.length > 0 ?<div className="tags">
      <Scrollbars noDefaultStyles style={{ height: "50px" }}>
      {tags.map((tag, index) => (
        <div className="tag-item" key={index}>
          <span className="text">{tag}</span>
          <span className="close" onClick={() => removeTag(index)}>
           X
          </span>
        </div>
      ))}
      </Scrollbars>
      </div> : null} 
     
      <input
        onKeyDown={handleKeyDown}
        type="text"
        name="tags"
        className="tags-input"
        placeholder="Main characters"
      />
    </div>
  );
}

export default TagsInput;

import Scrollbars from "react-custom-scrollbars-2";
import { Link } from "react-router-dom";
import Fact from "./fact/fact";
export default function FactList({ facts, setFacts , user }) {

  if (facts.length === 0)
    return (
      <p className="message">
        No stories for this category yet! Create the first one ✌️
      </p>
    );
  return (
    <Scrollbars noDefaultStyles style={{ height: "1000px" }} className= "fact-list">
      <section>
        <ul className="facts-list">
          {facts.map((fact) => (
            <Fact key={fact.id} fact={fact} setFacts={setFacts} user = {user}/>
          ))}
        </ul>
        <p>There are {facts.length} stories in the database. Add your own!</p>
        
      </section>
      
 </Scrollbars>
  );
}

import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React from "react";
import "./comment.css";
import moment from "moment";
import { Link } from "react-router-dom";
function Comment({ comment }) {
  return (
    <div className="comment">
      <div className="comment_header">
        <Avatar size={34} icon={<UserOutlined />} />{" "}
        <span><Link className="source" to={`/user/${comment.userId}`}>{comment.username || comment?.username } </Link></span>
      </div>
      <p>{comment.text}</p>
      <div className="time">
        {moment(new Date(comment.created_at), "YYYYMMDD").fromNow()}
      </div>
    </div>
  );
}

export default Comment;

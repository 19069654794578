export const CATEGORIES = [
    { name: "bildungsroman", color: "#3b82f6" },
    { name: "western", color: "#16a34a" },
    { name: "romance", color: "#ef4444" },
    { name: "historical", color: "#eab308" },
    { name: "horror", color: "#db2777" },
    { name: "thriller", color: "#14b8a6" },
    { name: "mystery", color: "#f97316" },
    { name: "Literary Fiction", color: "#8b5cf6" },
    { name: "Speculative Fiction", color: "#3b82f6" },
    { name: "Science Fiction", color: "#16a34a" },
    { name: "Fantasy", color: "#ef4444" },
    { name: "Dystopian", color: "#eab308" },
    { name: "Magical Realism", color: "#db2777" },
    { name: "Realist Literature", color: "#14b8a6" },
    { name: "Childrens", color: "#f97316" },
    { name: "Disney", color: "#8b5cf6" },
    { name: "Comedy", color: "#3b82f6" },
  ];
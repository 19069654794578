import { LeftOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useParams } from "react-router-dom";
import supabase from "../../supabase";
import Fact from "../fact/fact";
import './profile.css'
function Profile() {
  const [profile, setProfile] = useState();
  const [facts, setFacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const param = useParams();

  useEffect(
    function () {
      async function getProfile() {
        let query = supabase.from("profiles").select(`*`);
        const profile = await (await query.eq("id", param.id)).data[0];
        const { data: facts, error } = await supabase
          .from("facts")
          .select("*")
          .eq("userId", profile.id);
        setFacts(facts);
        setProfile(profile);
        setIsLoading(false);
      }

      getProfile();
    },
    [param.id]
  );
  return (
    <div className="profile">
     <Link to="/" className="goBack">
      <LeftOutlined /> go Back
      </Link>
      <div className="profile_header">
        <Avatar size={64} icon={<UserOutlined />} />{" "}
        <span>{profile?.username || profile?.email?.split("@")[0]}</span>
      </div>
      <Scrollbars  noDefaultStyles style={{ height: "1000px" }}>
        <section className="user-posts">
          <ul className="facts-list">
            {facts.map((fact) => (
              <Fact key={fact.id} fact={fact} setFacts={setFacts} />
            ))}
          </ul>
       {isLoading ? <Spin /> :  <p>There are no posts  in the database for this user.</p>}  
        </section>
      </Scrollbars>
    </div>
  );
}

export default Profile;

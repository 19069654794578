import React, { useEffect } from "react";
import supabase from "../supabase";
import { Auth, ThemeSupa } from "@supabase/auth-ui-react";
import { useNavigate } from "react-router-dom";
import "./login.css";
function Login({ setIsAuthenticated }) {
  const navigate = useNavigate();
  useEffect(() => {
    async function navigateToHome() {
      await supabase.auth.onAuthStateChange(async (event, session) => {
        if (event === "SIGNED_IN") {
          setIsAuthenticated(true);
          navigate("/");
        }
      });
    }
    navigateToHome();
  }, []);
  return (
    <div className="auth">
      <Auth
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa, ClassName: "amr" }}
        providers={["google", "facebook", "apple"]}
        theme="dark"
      />
    </div>
  );
}

export default Login;

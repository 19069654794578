import { useNavigate } from "react-router-dom";
import { CATEGORIES } from "../constant/constant";

export default function CategoryFilter({ setCurrentCategory , setOpen }) {
  const navigate = useNavigate();
  return (
    <aside>
      <ul>
        <li className="category">
          <button
            className="btn btn-all-categories"
            onClick={() => {
              setOpen &&  setOpen(false);
              navigate("/");
              setCurrentCategory("all");
            }}
          >
            All
          </button>
        </li>

        {CATEGORIES.map((cat) => (
          <li key={cat.name} className="category">
            <button
              className="btn btn-category"
              style={{ backgroundColor: cat.color }}
              onClick={() => {
                setOpen &&  setOpen(false)
                navigate("/");
                setCurrentCategory(cat.name);
              }}
            >
              {cat.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}

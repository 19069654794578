import { MenuOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import supabase from "../../supabase";
import LeftDrawer from "../drawer/drawer";
import "./header.css";
export default function Header({
  showForm,
  setShowForm,
  isAuthenticated,
  setIsAuthenticated,
}) {
  const appTitle = "AI STORY WALL";

  async function signOut() {
    localStorage.setItem("isAuthenticated", false);
    setOpen(false)
     await supabase.auth.signOut();
    setIsAuthenticated(false);
  }
  const [open , setOpen] = useState(false)
  return (
    <header className="header">
      <NavLink to="/" className="link">
        <div className="logo">
          <img
            src="/logo.png"
            height="68"
            width="68"
            alt="Today I Learned Logo"
          />
          <h1>{appTitle}</h1>
        </div>
      </NavLink>
     { <div className="mobileIcon">
        <MenuOutlined onClick={() => setOpen(true)}/>
      </div>}
      <LeftDrawer title = "NavMenue" open ={open} onClose = {()=> setOpen(false)}>
      <div className="mobileMenu">
        {isAuthenticated ? (
          <>
            <button className="btn btn-large btn-open" onClick={signOut}>
              logout
            </button>
          </>
        ) : (
          <NavLink to="/login" onClick={() => setOpen(false)} className="btn btn-large link">
            join us
          </NavLink>
        )}
      </div>
      </LeftDrawer>
      <div className="btn-group mobileNone">
        {isAuthenticated ? (
          <>
            <button
              className="btn btn-large btn-open"
              onClick={() => setShowForm((show) => !show)}
            >
              {showForm ? "Close" : "Write My Story"}
            </button>
            <button className="btn btn-large btn-open" onClick={signOut}>
              logout
            </button>
          </>
        ) : (
          <NavLink to="/login" className="btn btn-large link">
            join us
          </NavLink>
        )}
      </div>
    </header>
  );
}

import { useEffect, useState } from "react";
import { CATEGORIES } from "../../constant/constant";
import supabase from "../../supabase";
import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import {
  ShareAltOutlined,
  FilePdfOutlined,
  FacebookFilled,
  RedditSquareFilled,
  TwitterSquareFilled,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./fact.css";
import { Avatar, Popover, Tooltip } from "antd";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
export default function Fact({
  fact,
  noLimit,
  user,
  comments,
  setIsUpdate,
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [factUser, setFactUser] = useState();

  const isDisputed =
    fact.votesInteresting + fact.votesMindblowing < fact.votesFalse;

  useEffect(() => {
    async function getReactions() {
      const { data: factUser } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", fact.userId);
      setFactUser(factUser[0]);
    }
    getReactions();
  }, [isUpdating, comments]);
  async function handleVote(columnName) {
    let query = supabase.from("reactions").select(`*`);
    setIsUpdating(true);
    setIsUpdate && setIsUpdate(true);
    const isExist = await query.eq("postId", fact.id).eq("userId", user.id);
    if (isExist.data?.length > 0 && columnName === isExist.data[0]?.reaction) {
      await supabase
        .from("reactions")
        .update({ reaction: "deleted" })
        .eq("id", isExist.data[0].id);
      await supabase.from("reactions").delete().eq("id", isExist.data[0].id);
      setIsUpdating(false);
      setIsUpdate && setIsUpdate(false);
    } else if (isExist.data?.length > 0) {
      await supabase
        .from("reactions")
        .update({ reaction: columnName })
        .eq("userId", user.id)
        .eq("postId", fact.id);
      setIsUpdating(false);
      setIsUpdate &&  setIsUpdate(false);
    } else {
      await supabase
        .from("reactions")
        .insert([{ userId: user.id, postId: fact.id, reaction: columnName }]);
      setIsUpdating(false);
      setIsUpdate && setIsUpdate(false);
    }
  }
  const handleDownloadPdf = async () => {

    var doc = new jsPDF();

    doc.setFontSize(50);
    doc.text("Ai story wall", 70, 35);
    doc.addImage("/logo.png", "png", 30, 15, 30, 30);
    doc.setFontSize(25)
    doc.text(`${fact.title}`, 15, 45 , {maxWidth : 170});
    doc.setFontSize(15)
    doc.text(`${fact.text}`, 15, 75 , {maxWidth : 170});
    
    doc.setFontSize(30)
    doc.text(`https://www.aistorywall.com`, 15, 280 , {maxWidth : 170});
    
   doc.save(fact.title)
  };
  return (
    <li className="fact">
      <div className="fact_header">
        <Avatar
          size={34}
          icon={<UserOutlined />}
          src={factUser?.profileImage}
        />{" "}
        <span>
          {" "}
          <Link className="source" to={`/user/${fact.userId}`}>
            {fact.username || factUser?.username}{" "}
          </Link>
        </span>
      </div>

      <h3>{fact.title}</h3>
      <div className="fact-content">
        <p>
          {isDisputed ? <span className="disputed">[⛔️ DISPUTED]</span> : null}
          {noLimit ? fact?.text : fact?.text?.slice(0, 350)}
          {fact?.text?.length > 100 && !noLimit ? "..." : ""}
          {fact?.text?.length > 100 && !noLimit ? (
            <Link className="source" to={`/${fact.id}`}>
              (readMore)
            </Link>
          ) : null}
        </p>

        <span
          className="tag"
          style={{
            backgroundColor: CATEGORIES.find(
              (cat) => cat.name === fact.category
            ).color,
          }}
        >
          {fact.category}
        </span>
      </div>
      <div className="controllers">
        <div className="vote-buttons">
          <Link to={`/${fact.id}`} className="comments_count">
            <MessageOutlined /> {fact.comments}
          </Link>
          <button
            onClick={() => handleVote("votesInteresting")}
            disabled={isUpdating}
          >
            👍 {fact.interest}
          </button>
          <button
            onClick={() => handleVote("votesMindblowing")}
            disabled={isUpdating}
          >
            🤯 {fact.mindblowing}
          </button>
          <button
            onClick={() => handleVote("votesFalse")}
            disabled={isUpdating}
          >
            ⛔️ {fact.votesfalse}
          </button>
        </div>

        <Popover
          content={
            <>
              <FacebookShareButton
                quote={fact.title}
                hashtag="#aistorywall"
                url={`${process.env.REACT_APP_URL}/${fact.id}`}
              >
                <FacebookFilled />
              </FacebookShareButton>
              <TwitterShareButton
                title={`${fact.title + fact?.text?.substring(1, 200)}`}
                hashtags ={["aistorywall"]}
                url={`${process.env.REACT_APP_URL}/${fact.id}`}
              >
                <TwitterSquareFilled />
              </TwitterShareButton>
              <RedditShareButton
                title={`${fact.title + fact.text}`}
                url={`${process.env.REACT_APP_URL}/${fact.id}`}
              >
                <RedditSquareFilled />
              </RedditShareButton>
            </>
          }
          title="share to social media"
          className="share-to-social"
        >
          <ShareAltOutlined onClick={() => setIsOpenShare(!isOpenShare)} />
        </Popover>

        <Tooltip
          placement="top"
          title="download as pdf"
          onClick={handleDownloadPdf}
        >
          <FilePdfOutlined />
        </Tooltip>
      </div>
    </li>
  );
}

import { Drawer } from 'antd'
import React from 'react'
import './drawer.css';
function LeftDrawer(props) {
  return (
    <Drawer
    title={props.title}
    placement="left"
    closable={true}
    onClose={props.onClose}
    open={props.open}
    key='left'
    className='custom_drawer'
  >
   {props.children}
  </Drawer>
  )
}

export default LeftDrawer
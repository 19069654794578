import { useEffect, useState } from "react";
import supabase from "./supabase";
import "./style.css";
import Header from "./components/header/header";
import NewFactForm from "./components/factForm/newFactForm";
import Home from "./pages/home";
import {Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import { notification } from "antd";
import Privacy from "./pages/privacy";
import Terms from "./pages/Terms";
function App() {
  const [showForm, setShowForm] = useState(false);
  const [facts, setFacts] = useState([]);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("all");
  useEffect(
    function () {
      async function getFacts() {
        const realtime = await supabase
          .channel("facts")
          .on(
            "postgres_changes",
            { event: "INSERT", schema: "public", table: "facts" },
            (payload) => {
              setFacts((prevFacts) => [...prevFacts, payload.new]);
            }
          )
          .on(
            "postgres_changes",
            { event: "UPDATE", schema: "public", table: "facts" },
            (payload) => {
              console.log({ payload });
              setFacts((prevFacts) =>
                prevFacts.map((item) =>
                  item.id === payload.old.id
                    ? {
                        ...payload.new,
                        text: prevFacts.find(
                          (item) => item.id === payload.old.id
                        ).text,
                      }
                    : item
                )
              );
            }
          )
          .subscribe();
        setIsLoading(true);
        console.log({ realtime });
        let query = supabase.from("facts").select("*");
        if (currentCategory !== "all")
          query = query.eq("category", currentCategory);
        const { data: facts, error } = await query
          .order("interest", { ascending: false })
          .limit(1000);
        if (!error) setFacts(facts);
        else
          notification.error({
            message: `an error occurred`,
            description: "There was a problem getting data",
            placement: "bottomRight",
          });
        setIsLoading(false);
      }
      getFacts();
    },
    [currentCategory]
  );
  const [isAuthenticated, setIsAuthenticated] = useState();

  useEffect(() => {
    async function getUser() {
      const { data } = await supabase.auth.getSession();
      if (data.session.user.aud === "authenticated") {
        const { data: user } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", data.session.user.id);
        setUser({
          ...user[0],
          username: user[0].username
            ? user[0].username
            : user[0].email.split("@")[0],
        });
        setIsAuthenticated(true);
      } else setIsAuthenticated(false);
    }
    getUser();
  }, [isAuthenticated]);
  return (
    <>
      <Header
        showForm={showForm}
        setShowForm={setShowForm}
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
      {isAuthenticated && <div className="mobile_post_btn">
          <button
            className="btn btn-large btn-open"
            onClick={() => setShowForm((show) => !show)}
          >
            {showForm ? "Close" : "Write My Story"}
          </button>
        </div>}
      {showForm ? (
        <NewFactForm
          setFacts={setFacts}
          setShowForm={setShowForm}
          user={user}
        />
      ) : null}
      <Routes>

        <Route
          exact
          path="/"
          element={
            <Home
              facts={facts}
              setFacts={setFacts}
              setCurrentCategory={setCurrentCategory}
              isLoading={isLoading}
              user={user}
              currentCategory = {currentCategory}
            />
          }
        />
        <Route
          path="/login"
          element={<Login setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route
          path="/privacy"
          element={<Privacy />}
        />
        <Route
          path="/terms-of-use"
          element={<Terms />}
        />
        <Route
          path="*"
          element={
            <Home
              facts={facts}
              setFacts={setFacts}
              setCurrentCategory={setCurrentCategory}
              isLoading={isLoading}
              user={user}
              currentCategory = {currentCategory}
            />
          }
        />
      </Routes>
      
    </>
  );
}
export default App;

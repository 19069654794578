import { message, Modal, notification, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { CATEGORIES } from "../../constant/constant";
import supabase from "../../supabase";
import TagsInput from "../tagInput/TagsInput";
import { OpenAIApi, Configuration } from "openai";
import "./factForm.css";
export default function NewFactForm({ setFacts, setShowForm, user }) {
  const [title, setTitle] = useState("");
  const [story, setStory] = useState("");
  const [storyTitle, setStoryTitle] = useState("");
  // Fixed in a video text overlay
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const titleLength = title?.length;

  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const handlePost = async () => {
    setIsUploading(true);
    const { data: newFact, error } = await supabase
      .from("facts")
      .insert([
        { title: storyTitle, text: story, category, userId: user?.id , username : user.username },
      ])
      .select();
    setIsUploading(false);
    // 4. Add the new fact to the UI: add the fact to state
    if (!error) setFacts((facts) => [newFact[0], ...facts]);

    // 5. Reset input fields

    setCategory("");
    setStory("");
    setTitle("");
    // 6. Close the form
    setShowForm(false);
    setOpen(false);
  };
  const handleCancel = () => {
    setCategory("");
    setStory("");
    setTitle("");
    setOpen(false);
  };
  const openai = new OpenAIApi(configuration);
  async function handleSubmit(e) {
    // 1. Prevent browser reload
    e.preventDefault();
    if (title && category && titleLength <= 200) {
      setOpen(true);
      setIsSearching(true)
      const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: `write me a story in the ${category} genre about ${tags.toString()} the story is to be about ${title}.Generate a title for the story at the top of the text and add * after title.`,
        temperature: 0.6,
        max_tokens: 550,
        top_p: 1,
        frequency_penalty: 1,
        presence_penalty: 1,
      });
      setIsSearching(false)
      setStory(response.data.choices[0].text.split("*")[1]);
      setStoryTitle(response.data.choices[0].text.split("*")[0]);
    } else {
      notification.error({
        message: `an error occurred`,
        description:
          "you should fill all fields and title must less or equal to 200 characters",
        placement: "bottomRight",
      });
    }

    // 2. Check if data is valid. If so, create a new fact (no http check)

    // if (text && category && textLength <= 200) {
    //   // Original code checking http link
    //   //if (text && isValidHttpUrl(source) && category && textLength <= 200) {

    //   // 3. Create a new fact object
    //   // const newFact = {
    //   //   id: Math.round(Math.random() * 10000000),
    //   //   text,
    //   //   source,
    //   //   category,
    //   //   votesInteresting: 0,
    //   //   votesMindblowing: 0,
    //   //   votesFalse: 0,
    //   //   createdIn: new Date().getFullYear(),
    //   // };

    //   // 3. Upload fact to Supabase and receive the new fact object
    //   setIsUploading(true);
    //   const { data: newFact, error } = await supabase
    //     .from("facts")
    //     .insert([{ title , text , source, category }])
    //     .select();
    //   setIsUploading(false);

    //   // 4. Add the new fact to the UI: add the fact to state
    //   if (!error) setFacts((facts) => [newFact[0], ...facts]);

    //   // 5. Reset input fields
    //   setText("");
    //   setSource("");
    //   setCategory("");

    //   // 6. Close the form
    //   setShowForm(false);
    // }
  }
  return (
    <>
      <form className="fact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Whats your plot..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          disabled={isUploading}
        />

        <span>{200 - title.length}</span>

        {<TagsInput setTags={setTags} tags={tags} />}

        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          disabled={isUploading}
        >
          <option value="">Select Genre:</option>
          {CATEGORIES.map((cat) => (
            <option key={cat.name} value={cat.name}>
              {cat.name.toUpperCase()}
            </option>
          ))}
        </select>
        <button
          className="btn btn-large"
          type="button"
          disabled={isUploading}
          onClick={handleSubmit}
        >
          Write my Story
        </button>
      </form>
      <Modal
        open={open}
        title={title}
        okButtonProps={{
          style: {
            background: "green",
          },
          disabled : isSearching
        }}
        onOk={handlePost}
        confirmLoading={isUploading}
        cancelButtonProps={{ className: "cancel" }}
        okText="post"
        onCancel={handleCancel}
        wrapClassName="Story-modal"
      >
        {story?.length === 0 ?<div className="Ai-Loading"> <Spin /> <p>please wait AI is crafting your story.</p> </div>  : <p>{story}</p>}
      </Modal>
    </>
  );
}

import { FilterOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import CategoryFilter from "../components/categoryFilters";
import LeftDrawer from "../components/drawer/drawer";
import FactDetails from "../components/fact-details/fact-details";
import FactList from "../components/factList";
import Profile from "../components/profile/profile";
import "./home.css";
function Home({
  currentCategory,
  setCurrentCategory,
  isLoading,
  facts,
  setFacts,
  user,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <main className="main">
        <FilterOutlined className="filter" onClick={() => setOpen(true)} />
        <LeftDrawer title="filters" open={open} onClose={() => setOpen(false)}>
          <CategoryFilter
            setCurrentCategory={setCurrentCategory}
            setOpen={setOpen}
          />
        </LeftDrawer>

        <div className="web-filter">
          <CategoryFilter setCurrentCategory={setCurrentCategory} />
        </div>
        <Routes>
          <Route
            path="/"
            element={
              isLoading ? (
                <Spin />
              ) : (
                <FactList facts={facts} setFacts={setFacts} user={user} />
              )
            }
          />
          <Route path="/:id" element={<FactDetails user={user} />} />
          <Route
            path="user/:id"
            element={<Profile currentCategory={currentCategory} />}
          />
        </Routes>
      </main>
      <div className="footer">
        <Link to = "/privacy">privacy policy</Link>
        <Link to = "/terms-of-use">terms-of-use</Link>
      </div>
    </>
  );
}

export default Home;

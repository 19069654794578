import { LeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import supabase from "../../supabase";
import Comment from "../comment/comment";
import Fact from "../fact/fact";
import "./fact-details.css";
function FactDetails({ user }) {
  const [fact, setFact] = useState();
  const [comments, setComments] = useState([]);
  const [isUpdate , setIsUpdate] = useState(false)
  const [isLoading , setIsLoading] = useState(true)
  const param = useParams();
  const [form] = Form.useForm();
  useEffect(
    function () {
      async function getFacts() {
        let query = supabase.from("facts").select(`*`);
        const fact = await (await query.eq("id", param.id)).data[0];
        setFact(fact);
        setIsLoading(false)
      }
      getFacts();
    },
    [param.id , comments , isUpdate]
  );
  useEffect(
    function () {
      async function getComments() {
        let { data: comments, error } = await supabase
          .from("comments")
          .select(`*`)
          .eq("postId", param.id);
        setComments(comments);
      }
      getComments();
    },
    [param.id]
  );
  const handleFinish = async (values) => {
      const { data: comment, error } = await supabase
        .from("comments")
        .insert([
          { username: user.username, postId: fact.id, ...values  , userId : user.id },
        ])
        .select("*");
if (error) {notification.error({
  message: `an error occurred`,
  description: "There was a problem please try again",
  placement: "bottomRight",
});} else {
  setComments((prevComments) => [...prevComments, ...comment]);
  form.resetFields();
}
   
    
  };
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);
  console.log({h : form.getFieldValue("comment")})
  return (
    <div className="post-details">
      <Link to="/" className="goBack">
      <LeftOutlined /> go Back
      </Link>
      {fact ? (
        <>
          <Fact
            key={fact.id}
            fact={fact}
            setIsUpdate = {setIsUpdate}
            setFacts={setFact}
            noLimit
            user={user}
            comments = {comments}
          />
          {comments?.map((item) => (
            <Comment comment={item} />
          ))}
          <Form onFinish={handleFinish} form={form} className ='comment_form'>

            <Form.Item name="text" rules={[{ required: true, message: 'Please input your comment!' }]}>
              <Input.TextArea placeholder="write your comment"  />
            </Form.Item>
            <div className="submit-container">
            <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
            Post 
          </Button>
        )}
      </Form.Item>
            </div>
          </Form>
        </>
      ) : isLoading ? <Spin /> :   <p className="message">
      No stories for this Id yet! 🥲
    </p>}
    </div>
  );
}

export default FactDetails;
